import { useContext, useEffect, useState } from 'react';
import { AmplienceImageCard } from '@Types/amplience/amplienceComponents';
import classNames from 'classnames';
import NextLink from 'components/common/NextLink';
import { RENDER_PRIORITY_TRESHOLD } from 'helpers/constants/amplienceConstants';
import { LayoutContext } from 'frontastic/provider/layout';
import { ButtonProps } from '../ampliance-cta-list-wrapper';
import styles from './ampliance-hero-banner.module.scss';
import BannerSectionWithImage from './banner_section_with_image';

type AmplienceItemsHorizontalPosition = 'start' | 'center' | 'end';
type AmplienceItemsVerticalPosition = 'normal' | 'center' | 'end';
type AmplianceHeroBannerImageLayout = 'Full width' | 'Three way split' | '50:50' | '60:40';

export interface BannerSection {
  priority?: boolean;
  buttonList: ButtonProps[];
  horizontalAligment: AmplienceItemsHorizontalPosition;
  verticalAligment: AmplienceItemsVerticalPosition;
  fullWidthContent: boolean;
  heading: {
    text: string;
    textColour: string;
  };
  image: AmplienceImageCard;
  subheading: {
    text: string;
    textColour: string;
  };
  description: {
    text: string;
    textColour: string;
  };
  layout: string;
  imageIndex: number;
  backgroundColor: string;
  overlay: OverlayEffectProps;
}

export interface OverlayEffectProps {
  isVisible: boolean;
  overlayColour: string;
}

export interface Props {
  content: {
    _meta: any;
    bleedEffect: boolean;
    overlayEffect: OverlayEffectProps;
    shortBanner: boolean;
    imageLayout: AmplianceHeroBannerImageLayout;
    bannerSections: BannerSection[];
    bgColor: string;
    index: number;
  };
}
const AmplienceHeroBanner = ({ content }: Props) => {
  const layout = useContext(LayoutContext);

  useEffect(() => {
    //we do this check first to avoid unecessary layout updates
    if (content?.bleedEffect) {
      layout.update.setBleedingBanner(true);
    }
    return () => {
      //same here
      if (content.bleedEffect) {
        layout.update.setBleedingBanner(false);
      }
    };
  }, []);

  return (
    <>
      <section data-testid="hero-banner-section" id="hero-banner-section">
        <div className={classNames(styles.heroBannerSection, 'hero-banner_wrapper')}>
          {content.bannerSections.map((element, i) => (
            <div
              key={`bannerSection-${element.heading.text}`}
              data-testid={`bannerSection-${element.heading.text}`}
              className={classNames(styles.bannerSectionWrapper, {
                [styles.full_width]: content.imageLayout === 'Full width' && content.bannerSections.length === 1,
                [styles.sixty_forty]: content.imageLayout === '60:40' && content.bannerSections.length === 2,
                [styles.fifty_fifty]: content.imageLayout === '50:50' && content.bannerSections.length === 2,
                [styles.three_way_split]:
                  content.imageLayout === 'Three way split' || content.bannerSections.length === 3,
                [styles.shortBanner]: content.shortBanner,
              })}
            >
              <NextLink href={element.buttonList?.[0]?.button.link ?? ''}>
                <BannerSectionWithImage
                  priority={content.index <= RENDER_PRIORITY_TRESHOLD}
                  heading={element.heading}
                  image={element.image}
                  subheading={element.subheading}
                  horizontalAligment={element.horizontalAligment}
                  verticalAligment={element.verticalAligment}
                  fullWidthContent={element.fullWidthContent}
                  layout={content.imageLayout}
                  imageIndex={i}
                  backgroundColor={`${content.bgColor ?? '#e8e8e8'}`}
                  description={element.description}
                  buttonList={element.buttonList}
                  overlay={content.overlayEffect}
                />
              </NextLink>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default AmplienceHeroBanner;
