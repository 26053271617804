import { useContext } from 'react';
import Image from 'next/image';
import { MIN_LAPTOP_SCREEN_SIZE } from 'helpers/constants/screenSizes';
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import { LayoutContext } from 'frontastic/provider/layout';
import { BannerSection } from '..';
import AmplienceButton from '../../amplience-button';
import styles from './banner_section_with_image.module.scss';

const ASPECTS = [
  { layout: 'Full width', index: 0, aspectMobile: '9:16', aspectDesktop: '16:9', size: '100vw' },
  { layout: 'Three way split', index: 0, aspectMobile: '3:5', aspectDesktop: '1:1', size: '50vw' },
  { layout: 'Three way split', index: 1, aspectMobile: '1:1', aspectDesktop: '9:7', size: '50vw' },
  { layout: 'Three way split', index: 2, aspectMobile: '1:1', aspectDesktop: '9:7', size: '50vw' },
  { layout: '60:40', index: 0, aspectMobile: '3:5', aspectDesktop: '1:1', size: '60vw' },
  { layout: '60:40', index: 1, aspectMobile: '1:1', aspectDesktop: '2:3', size: '40vw' },
  { layout: '50:50', index: 0, aspectMobile: '4:5', aspectDesktop: '7:9', size: '50vw' },
  { layout: '50:50', index: 1, aspectMobile: '4:5', aspectDesktop: '7:9', size: '50vw' },
];

const BannerSectionWithImage = (props: BannerSection) => {
  const layout = useContext(LayoutContext);
  function bannerImageLoader({ src, width }) {
    const aspect = ASPECTS.find((aspect) => aspect.layout === props.layout && aspect.index === props.imageIndex);
    if (width < MIN_LAPTOP_SCREEN_SIZE) {
      return `${src}?w=${width}&fmt=auto&$rebuild-hero-banner$&aspect=${aspect.aspectMobile}`;
    }
    return `${src}?w=${width}&fmt=auto&$rebuild-hero-banner$&aspect=${aspect.aspectDesktop}`;
  }

  return (
    <div className={styles.mainSection}>
      {props.overlay?.isVisible && (
        <div
          className={styles.overlay}
          style={{
            backgroundColor: props.overlay?.overlayColour,
          }}
        ></div>
      )}
      <div style={{ backgroundColor: props.backgroundColor }} className={styles.imageWrapper}>
        {props.image?.contentImage?.name &&
          (props.priority ? (
            <Image
              priority
              {...{ fetchpriority: 'high' }}
              data-testid="heroBanner-section-image"
              src={createImageUrlFromAmplienceImageCard(props.image)}
              alt={props.image.metaTextAleternative}
              layout="fill"
              loader={bannerImageLoader}
              sizes={
                layout.isMobile
                  ? '100vw'
                  : ASPECTS.find((aspect) => aspect.layout === props.layout && aspect.index === props.imageIndex)?.size
              }
              className={styles.image}
            />
          ) : (
            <Image
              data-testid="heroBanner-section-image"
              src={createImageUrlFromAmplienceImageCard(props.image)}
              alt={props.image.metaTextAleternative}
              layout="fill"
              loader={bannerImageLoader}
              sizes={
                layout.isMobile
                  ? '100vw'
                  : ASPECTS.find((aspect) => aspect.layout === props.layout && aspect.index === props.imageIndex)?.size
              }
              className={styles.image}
            />
          ))}
      </div>
      <div className={styles.upperPart}>
        <div
          className={styles.content}
          style={{
            justifyContent: props?.verticalAligment,
            alignItems: props?.horizontalAligment,
            width: !layout.isMobile && (props?.fullWidthContent ? '100%' : '50%'),
          }}
        >
          {props?.subheading?.text && (
            <h2
              className={styles.subheading}
              style={{ color: props.subheading.textColour, textAlign: props.horizontalAligment }}
            >
              {props.subheading.text}
            </h2>
          )}
          {props?.heading?.text && (
            <h1
              className={styles.heading}
              style={{ color: props.heading.textColour, textAlign: props.horizontalAligment }}
              data-testid="hero-banner-heading"
            >
              {props.heading.text}
            </h1>
          )}
          {props?.description?.text && (
            <h2
              className={styles.description}
              style={{ color: props.description.textColour, textAlign: props.horizontalAligment }}
            >
              {props?.description?.text}
            </h2>
          )}

          {props?.buttonList?.map((val, index) => {
            return (
              <div key={index} className={styles.buttonWrapper} data-testid={'hero-banner-button-' + val.button.text}>
                <AmplienceButton content={val.button}></AmplienceButton>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BannerSectionWithImage;
